import { dew as _getClassDew } from "./get-class";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var getClass = _getClassDew();
  /**
   * Returns `true` when `object` is an `arguments` object, `false` otherwise
   *
   * @alias module:samsam.isArguments
   * @param  {*}  object - The object to examine
   * @returns {boolean} `true` when `object` is an `arguments` object
   */


  function isArguments(object) {
    return getClass(object) === "Arguments";
  }

  exports = isArguments;
  return exports;
}