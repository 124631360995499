var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var div = typeof document !== "undefined" && document.createElement("div");
  /**
   * Returns `true` when `object` is a DOM element node.
   *
   * Unlike Underscore.js/lodash, this function will return `false` if `object`
   * is an *element-like* object, i.e. a regular object with a `nodeType`
   * property that holds the value `1`.
   *
   * @alias module:samsam.isElement
   * @param {object} object The object to examine
   * @returns {boolean} Returns `true` for DOM element nodes
   */

  function isElement(object) {
    if (!object || object.nodeType !== 1 || !div) {
      return false;
    }

    try {
      object.appendChild(div);
      object.removeChild(div);
    } catch (e) {
      return false;
    }

    return true;
  }

  exports = isElement;
  return exports;
}