import _commons from "@sinonjs/commons";
import { dew as _deepEqualDew } from "../deep-equal";
import { dew as _isMatcherDew } from "./is-matcher";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var every = _commons.prototypes.array.every;
  var concat = _commons.prototypes.array.concat;
  var typeOf = _commons.typeOf;

  var deepEqualFactory = _deepEqualDew().use;

  var isMatcher = _isMatcherDew();

  var keys = Object.keys;
  var getOwnPropertySymbols = Object.getOwnPropertySymbols;
  /**
   * Matches `actual` with `expectation`
   *
   * @private
   * @param {*} actual A value to examine
   * @param {object} expectation An object with properties to match on
   * @param {object} matcher A matcher to use for comparison
   * @returns {boolean} Returns true when `actual` matches all properties in `expectation`
   */

  function matchObject(actual, expectation, matcher) {
    var deepEqual = deepEqualFactory(matcher);

    if (actual === null || actual === undefined) {
      return false;
    }

    var expectedKeys = keys(expectation);
    /* istanbul ignore else: cannot collect coverage for engine that doesn't support Symbol */

    if (typeOf(getOwnPropertySymbols) === "function") {
      expectedKeys = concat(expectedKeys, getOwnPropertySymbols(expectation));
    }

    return every(expectedKeys, function (key) {
      var exp = expectation[key];
      var act = actual[key];

      if (isMatcher(exp)) {
        if (!exp.test(act)) {
          return false;
        }
      } else if (typeOf(exp) === "object") {
        if (!matchObject(act, exp, matcher)) {
          return false;
        }
      } else if (!deepEqual(act, exp)) {
        return false;
      }

      return true;
    });
  }

  exports = matchObject;
  return exports;
}