import { dew as _identicalDew } from "./identical";
import { dew as _isArgumentsDew } from "./is-arguments";
import { dew as _isElementDew } from "./is-element";
import { dew as _isNegZeroDew } from "./is-neg-zero";
import { dew as _isSetDew } from "./is-set";
import { dew as _isMapDew } from "./is-map";
import { dew as _matchDew } from "./match";
import { dew as _deepEqualDew } from "./deep-equal";
import { dew as _createMatcherDew } from "./create-matcher";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  /**
   * @module samsam
   */
  var identical = _identicalDew();

  var isArguments = _isArgumentsDew();

  var isElement = _isElementDew();

  var isNegZero = _isNegZeroDew();

  var isSet = _isSetDew();

  var isMap = _isMapDew();

  var match = _matchDew();

  var deepEqualCyclic = _deepEqualDew().use(match);

  var createMatcher = _createMatcherDew();

  exports = {
    createMatcher: createMatcher,
    deepEqual: deepEqualCyclic,
    identical: identical,
    isArguments: isArguments,
    isElement: isElement,
    isMap: isMap,
    isNegZero: isNegZero,
    isSet: isSet,
    match: match
  };
  return exports;
}