var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  /**
   * Returns `true` when `value` is `-0`
   *
   * @alias module:samsam.isNegZero
   * @param {*} value A value to examine
   * @returns {boolean} Returns `true` when `value` is `-0`
   */
  function isNegZero(value) {
    return value === 0 && 1 / value === -Infinity;
  }

  exports = isNegZero;
  return exports;
}