import _commons from "@sinonjs/commons";
import { dew as _matcherPrototypeDew } from "./matcher-prototype";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var isPrototypeOf = _commons.prototypes.object.isPrototypeOf;

  var matcherPrototype = _matcherPrototypeDew();
  /**
   * Returns `true` when `object` is a matcher
   *
   * @private
   * @param {*} object A value to examine
   * @returns {boolean} Returns `true` when `object` is a matcher
   */


  function isMatcher(object) {
    return isPrototypeOf(matcherPrototype, object);
  }

  exports = isMatcher;
  return exports;
}