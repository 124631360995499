import _commons from "@sinonjs/commons";
import { dew as _arrayTypesDew } from "./array-types";
import _typeDetect from "type-detect";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var functionName = _commons.functionName;
  var indexOf = _commons.prototypes.array.indexOf;
  var map = _commons.prototypes.array.map;

  var ARRAY_TYPES = _arrayTypesDew();

  var type = _typeDetect;
  /**
   * Returns `true` when `object` is an array type, `false` otherwise
   *
   * @param  {*}  object - The object to examine
   * @returns {boolean} `true` when `object` is an array type
   * @private
   */

  function isArrayType(object) {
    return indexOf(map(ARRAY_TYPES, functionName), type(object)) !== -1;
  }

  exports = isArrayType;
  return exports;
}