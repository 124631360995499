import { dew as _isMatcherDew } from "./is-matcher";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var isMatcher = _isMatcherDew();
  /**
   * Throws a TypeError when `value` is not a matcher
   *
   * @private
   * @param {*} value The value to examine
   */


  function assertMatcher(value) {
    if (!isMatcher(value)) {
      throw new TypeError("Matcher expected");
    }
  }

  exports = assertMatcher;
  return exports;
}