import _commons from "@sinonjs/commons";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var typeOf = _commons.typeOf;
  /**
   * Returns `true` for iterables
   *
   * @private
   * @param {*} value A value to examine
   * @returns {boolean} Returns `true` when `value` looks like an iterable
   */

  function isIterable(value) {
    return Boolean(value) && typeOf(value.forEach) === "function";
  }

  exports = isIterable;
  return exports;
}