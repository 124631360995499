import _commons from "@sinonjs/commons";
import { dew as _matchObjectDew } from "./match-object";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var functionName = _commons.functionName;
  var join = _commons.prototypes.array.join;
  var map = _commons.prototypes.array.map;
  var stringIndexOf = _commons.prototypes.string.indexOf;
  var valueToString = _commons.valueToString;

  var matchObject = _matchObjectDew();

  var createTypeMap = function (match) {
    return {
      function: function (m, expectation, message) {
        m.test = expectation;
        m.message = message || "match(" + functionName(expectation) + ")";
      },
      number: function (m, expectation) {
        m.test = function (actual) {
          // we need type coercion here
          return expectation == actual; // eslint-disable-line eqeqeq
        };
      },
      object: function (m, expectation) {
        var array = [];

        if (typeof expectation.test === "function") {
          m.test = function (actual) {
            return expectation.test(actual) === true;
          };

          m.message = "match(" + functionName(expectation.test) + ")";
          return m;
        }

        array = map(Object.keys(expectation), function (key) {
          return key + ": " + valueToString(expectation[key]);
        });

        m.test = function (actual) {
          return matchObject(actual, expectation, match);
        };

        m.message = "match(" + join(array, ", ") + ")";
        return m;
      },
      regexp: function (m, expectation) {
        m.test = function (actual) {
          return typeof actual === "string" && expectation.test(actual);
        };
      },
      string: function (m, expectation) {
        m.test = function (actual) {
          return typeof actual === "string" && stringIndexOf(actual, expectation) !== -1;
        };

        m.message = "match(\"" + expectation + "\")";
      }
    };
  };

  exports = createTypeMap;
  return exports;
}