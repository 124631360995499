import _commons from "@sinonjs/commons";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var forEach = _commons.prototypes.set.forEach;
  /**
   * Returns `true` when `s1` is a subset of `s2`, `false` otherwise
   *
   * @private
   * @param  {Array|Set}  s1      The target value
   * @param  {Array|Set}  s2      The containing value
   * @param  {Function}  compare A comparison function, should return `true` when
   *                             values are considered equal
   * @returns {boolean} Returns `true` when `s1` is a subset of `s2`, `false`` otherwise
   */

  function isSubset(s1, s2, compare) {
    var allContained = true;
    forEach(s1, function (v1) {
      var includes = false;
      forEach(s2, function (v2) {
        if (compare(v2, v1)) {
          includes = true;
        }
      });
      allContained = allContained && includes;
    });
    return allContained;
  }

  exports = isSubset;
  return exports;
}