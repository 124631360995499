var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  /**
   * Returns `true` when `value` is an instance of Date
   *
   * @private
   * @param  {Date}  value The value to examine
   * @returns {boolean}     `true` when `value` is an instance of Date
   */
  function isDate(value) {
    return value instanceof Date;
  }

  exports = isDate;
  return exports;
}