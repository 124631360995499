import { dew as _isNanDew } from "./is-nan";
import { dew as _isNegZeroDew } from "./is-neg-zero";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var isNaN = _isNanDew();

  var isNegZero = _isNegZeroDew();
  /**
   * Strict equality check according to EcmaScript Harmony's `egal`.
   *
   * **From the Harmony wiki:**
   * > An `egal` function simply makes available the internal `SameValue` function
   * > from section 9.12 of the ES5 spec. If two values are egal, then they are not
   * > observably distinguishable.
   *
   * `identical` returns `true` when `===` is `true`, except for `-0` and
   * `+0`, where it returns `false`. Additionally, it returns `true` when
   * `NaN` is compared to itself.
   *
   * @alias module:samsam.identical
   * @param {*} obj1 The first value to compare
   * @param {*} obj2 The second value to compare
   * @returns {boolean} Returns `true` when the objects are *egal*, `false` otherwise
   */


  function identical(obj1, obj2) {
    if (obj1 === obj2 || isNaN(obj1) && isNaN(obj2)) {
      return obj1 !== 0 || isNegZero(obj1) === isNegZero(obj2);
    }

    return false;
  }

  exports = identical;
  return exports;
}